import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const getWebsiteContent = async () => {
  const response = await fetch("websiteContent.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
};

const NewsModal = ({ isOpen, handleClose }) => {
  console.log("debug pathname", window.location.pathname);
  const searchParams = new URLSearchParams(window.location.search);
  const showSecretPage = searchParams.get("geheim") === "true";

  const [websiteContent, setWebsiteContent] = useState();

  useEffect(() => {
    const init = async () => {
      const wc = await getWebsiteContent();
      const wcPrepared = wc.table.rows.map((row) => ({
        dateColumn: row.c[0]?.v,
        contentColumn: row.c[1]?.v,
      }));

      const readymadeData = {};
      let mostRecentDate;
      wcPrepared.slice(1).forEach((row) => {
        if (row.dateColumn) {
          readymadeData[row.dateColumn] = [row.contentColumn];
          mostRecentDate = row.dateColumn;
        } else {
          if (mostRecentDate) {
            readymadeData[mostRecentDate].push(row.contentColumn);
          } else {
            // TODO error case
          }
        }
      });

      setWebsiteContent(readymadeData);
    };
    init();
  }, []);

  console.log("readymadeData", websiteContent);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <div
        onClick={handleClose}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
          letterSpacing: 1,
        }}
      >
        {!websiteContent || !showSecretPage ? (
          <div style={{ textAlign: "center" }}>
            <h2>Aktuelles</h2>
            <p>(folgt demnächst)</p>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h2>Aktuelles</h2>
            {Object.keys(websiteContent).map((date) => {
              const content = websiteContent[date];
              return (
                <div style={{ textAlign: "left", marginBottom: 24 }}>
                  <div style={{ color: "grey" }}>{date}</div>
                  {content.map((row) => (
                    <div style={{ color: "black", marginBottom: 8 }}>{row}</div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NewsModal;

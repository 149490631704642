const colorPalette1 = {
  // source: https://coolors.co/8f6571-cbcdcc-747b81-9d9298-caaba6
  colorA: "#8F6571",
  colorB: "#CBCDCC",
  colorC: "#747B81",
  colorD: "#9D9298",
  colorE: "#CAABA6",
  colorF: "#eeeeee",
};

export default colorPalette1;

import React from "react";
import { animated, useSpring } from "react-spring";
import colors from "../colors";
import constants from "../constants";

const TitleSection = ({ children, showFilters, setShowFilters }) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: showFilters ? 1 : 0,
    config: { duration: 100 },
  });

  return (
    <animated.div
      className={"title"}
      style={{
        height: 120 + constants.FILTER_SECTION_HEIGHT,
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        fontSize: "min(3rem, 10vw)",
        color: colors.colorB,
        flexDirection: "column",
        marginBottom: 20,
        position: "relative",
      }}
    >
      <animated.div
        className={"placeholder-div"}
        style={{
          height: x.to({
            range: [0, 1],
            output: [constants.FILTER_SECTION_HEIGHT, 0],
          }),
        }}
      />
      <animated.div
        style={{
          display: "flex",
          flex: x.to({
            range: [0, 1],
            output: [0.5, 0],
          }),
          fontSize: x.to({
            range: [0, 1],
            output: ["1rem", "0rem"],
          }),
          opacity: 0.65,
          filter: "drop-shadow(0px 0px 45px black)",
          //  marginTop: "0.6rem",
          marginBottom: x.to({
            range: [0, 1],
            output: [10, 0],
          }),
          width: "100vw",
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            top: 0,
            position: "absolute",
            backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.35,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Aquarelle - Kunst aus Stockerau
        </div>
        <div
          style={{
            height: "100%",
            top: 0,
            position: "absolute",
            //   backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.75,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Aquarelle - Kunst aus Stockerau
        </div>
      </animated.div>
      <animated.div
        className={"elvira-kurzmann-title"}
        style={{
          width: "100vw",
          display: "flex",
          flex: x.to({
            range: [0, 1],
            output: [1.5, 0],
          }),
          opacity: x.to({
            range: [0, 1],
            output: [0.65, 0],
          }),
          filter: "drop-shadow(0px 0px 45px black)",
          position: "relative",
          marginBottom: x.to({
            range: [0, 1],
            output: [10, 0],
          }),
        }}
      >
        <div
          style={{
            height: "100%",
            position: "absolute",
            backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.35,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Elvira Kurzmann
        </div>
        <div
          style={{
            height: "100%",
            position: "absolute",
            //   backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.75,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Elvira Kurzmann
        </div>
      </animated.div>
      <animated.div
        style={{
          width: "100vw",
          display: "flex",
          flex: x.to({
            range: [0, 1],
            output: [0.5, 1],
          }),
          opacity: 1,
          filter: "drop-shadow(0px 0px 45px black)",
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "absolute",
            backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.2275,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          className={"filter-container"}
          style={{
            height: "100%",
            position: "absolute",
            // backgroundColor: "black",
            width: "100%",
            display: "flex",
            opacity: 0.5,
            filter: "drop-shadow(0px 0px 45px black)",
            justifyContent: "center",
            alignItems: "center",
            // overflowX: "scroll",
          }}
        >
          {children}
          <style>{`
        
          
           .filter-container { -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
  }
  
    @media screen and (min-width:900px) {
  .show-filters, .hide-filters {
  display:none !important
  }
  }
 
  
    @media screen and (max-width:900px) {
  .show-filters {
     ${showFilters ? "display: none !important;" : ""}
  }
  .hide-filters {
     ${!showFilters ? "display: none !important;" : ""}
  }
  .filter-tag-basic {
        ${!showFilters ? "display: none !important;" : ""}
    }
  }
 `}</style>
        </div>
      </animated.div>
    </animated.div>
  );
};

export default TitleSection;

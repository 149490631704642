import { getAnalyticsUrls } from "./getAnalyticsUrls";
import { cheese } from "cheese-log";

const { apiUrl, frontendUrl } = getAnalyticsUrls();

export async function recordPageView() {
  const userAgent = navigator.userAgent;
  const referrer = document.referrer;
  const customProps = { locales: navigator.language };

  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000 /* ms */);

    await fetch(
      apiUrl, // <- represents our analytics url
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "User-Agent": userAgent,
        },
        body: JSON.stringify({
          name: "pageview",
          domain: frontendUrl, // <- defines which app/website we're monitoring (includes env distinction)
          url: "/", // <- record which page was visited
          referrer: referrer,
          props: customProps,
        }),
        signal: controller.signal,
      },
    );

    clearTimeout(timeoutId);
  } catch (e) {
    cheese.error("recordPageView error to /: ", e);
  }
}

import React, { Component } from "react";
import constants from "../constants";

class FilterTag extends Component {
  render() {
    const { name, title, active, onClick, color, className } = this.props;
    return (
      <div
        className={className || "filter-tag-basic"}
        title={title}
        onClick={onClick}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: 20,
          fontSize: 11,
          fontWeight: "bold",
          padding: "3px 5px",
          margin: constants.FILTERS_MARGIN,
          backgroundColor: color,
          color: "black",
          opacity: !active && 0.46,
          cursor: "pointer",
          filter: className === "hide-filters" ? "invert" : "",
          //   height: "max-content",
        }}
      >
        {name}
      </div>
    );
  }
}

export default FilterTag;

import React, { Component } from "react";
import { menuStyles } from "../pages/main/styles";
import { slide as Menu } from "react-burger-menu";
import colors from "../colors";
import CloseSquareFilled from "@ant-design/icons/lib/icons/CloseSquareFilled";

const Drawer = (props) => {
  const {
    isOpen,
    handleToggleDrawer,
    handleToggleAboutMeModal,
    handleToggleNewsModal,
    handleToggleContactModal,
    handleToggleSiteNoticeModal,
  } = props;

  return (
    <Menu
      right
      styles={{ ...menuStyles }}
      isOpen={isOpen}
      onStateChange={(drawerState) => handleToggleDrawer(drawerState.isOpen)}
    >
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <CloseButton handleToggleDrawer={handleToggleDrawer} />
      </div>
      <MenuItem
        id={"menu-entry-about-me"}
        name={"Über mich"}
        onClick={() => {
          handleToggleAboutMeModal(true);
          handleToggleDrawer(false);
        }}
      />
      <MenuItem
        id={"menu-entry-news"}
        name={"Aktuelles"}
        onClick={() => {
          handleToggleNewsModal(true);
          handleToggleDrawer(false);
        }}
      />
      <MenuItem
        id={"menu-entry-contact-form"}
        name={"Kontakt"}
        onClick={() => {
          handleToggleContactModal(true);
          handleToggleDrawer(false);
        }}
      />
      <MenuItem
        id={"menu-entry-site-notice"}
        name={"Impressum"}
        onClick={() => {
          handleToggleSiteNoticeModal(true);
          handleToggleDrawer(false);
        }}
      />
    </Menu>
  );
};

class MenuItem extends Component {
  state = { isHovered: false };

  render() {
    const { id, name, onClick } = this.props;
    return (
      <div
        id={id}
        className="bm-item"
        onClick={onClick}
        style={{
          display: "flex",
          margin: "1rem",
          color: this.state.isHovered ? colors.colorD : colors.colorC,
          textDecoration: "none",
          cursor: "pointer",
        }}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {name}
      </div>
    );
  }
}

class CloseButton extends Component {
  state = { isHovered: false };

  render() {
    return (
      <div
        onClick={() => {
          this.props.handleToggleDrawer(false);
        }}
        style={{
          cursor: "pointer",
          marginTop: "1.4rem",
          marginRight: "1.8rem",
          zIndex: 99999,
          color: "white",
          fontSize: "1.8rem",
          opacity: this.state.isHovered ? 0.9 : 0.65,
        }}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <CloseSquareFilled />
      </div>
    );
  }
}

export default Drawer;

import React from "react";
import FilterTag from "./filter-tag";
import colors from "../colors";

const FilterSection = (props) => {
  const {
    setShowFilters,
    focusedPictureData,
    paintingSubjectFilter,
    availablePaintingSubjects,
    setPaintingSubjectFilter,
  } = props;

  return (
    <div
      className={"filters"}
      style={{
        // height: constants.FILTER_SECTION_HEIGHT,
        width: "max-content",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        pointerEvents: focusedPictureData && "none",
        //   overflowX:"scroll"
        // marginTop: "0.6rem",
      }}
    >
      {[
        "FILTER EINBLENDEN" +
          (paintingSubjectFilter
            ? ` - FILTER '${paintingSubjectFilter}' AKTIV!`
            : ""),
        "FILTER AUSBLENDEN",
        "ALLE THEMEN",
        ...availablePaintingSubjects,
      ].map((ps, index) => (
        <FilterTag
          key={`filter-tag-${ps}`}
          name={ps}
          color={colors.colorF}
          className={
            index === 0
              ? "show-filters"
              : index === 1
                ? "hide-filters"
                : undefined
          }
          active={
            index <= 1
              ? true
              : index === 2
                ? !paintingSubjectFilter
                : !paintingSubjectFilter || paintingSubjectFilter === ps
          }
          onClick={
            index === 0
              ? () => setShowFilters(true)
              : index === 1
                ? () => setShowFilters(false)
                : () => setPaintingSubjectFilter(ps, index)
          }
        />
      ))}
    </div>
  );
};

export default FilterSection;
